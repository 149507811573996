$c-red: #e43d30;
$c-green: #17988b;
$s-banner: 70px;

body {
	font-size: 16px;
	overflow-x: hidden;
	width: 100vw;
}

.order-btn {
	background: #28988a;
	// background: url('./assets//btn-bg.svg');
	// background-size: 50%;
	// background-position: 0;
	// background-repeat: repeat;
	filter: drop-shadow(0 10px 10px rgba(black, 0.75));
	transition: all 0.25s linear;
	overflow: hidden;
	&.other-shadow {
		filter: drop-shadow(0 10px 10px rgba(#0c7a6c, 0.75));
	}
	&.no-shadow {
		filter: drop-shadow(0 0 0 rgba(black, 0));
	}
	.bg-faker {
		background: #0c7a6c;
		width: 0;
		transition: all 0.5s ease-in-out;
		will-change: width;
		z-index: 1;
	}
	img {
		filter: drop-shadow(0 10px 10px rgba(black, 0.5));
		height: 35px;
		left: 20px;
		top: 50%;
		position: absolute;
		transform: translate(0, -40%);
		width: auto;
		z-index: 2;
	}
	span {
		letter-spacing: 2px;
		text-shadow: 0 5px 8px rgba(black, 0.5);
		transform: translateZ(10px);
		z-index: 2;
	}
	&:hover {
		// background-position: 50px !important;
		.bg-faker {
			width: 100%;
		}
	}
}

.main-header {
	// background-size: cover !important;
	background-position: 57% top !important;
	height: 90vh;
	overflow: hidden;
	width: 100vw;
	.main-logo {
		filter: drop-shadow(0 40px 10px rgba(black, 0.5));
	}
	@media (max-width: 1220px) {
		background-position: center !important;
	}
}

.the-pizza-slide {
	position: relative;
	h2 {
		// background: rgba(white, 0.35);
		background: rgba(#18988b, 0.85);
		backdrop-filter: blur(10px);
		box-shadow: 0 15px 15px -10px rgba(#18988b, 0.75);
		left: 50%;
		letter-spacing: 2px;
		opacity: 0 !important;
		padding: 0.5em 1em;
		position: absolute;
		top: 50%;
		transform: translate(-50%, 0) !important;
		transition: all 0.25s ease-in-out;
		will-change: transform;
	}
	&:hover {
		h2 {
			opacity: 1 !important;
			transform: translate(-50%, -50%) !important;
		}
	}
	@media (max-width: 540px) {
		&.swiper-slide-active {
			h2 {
				opacity: 1 !important;
				transform: translate(-50%, -50%) !important;
			}
		}
	}
}

.main-nav-header {
	background: $c-red;
	color: white;
	cursor: pointer;
	height: $s-banner;
	transition: none !important;
	max-width: 100vw !important;
	z-index: 10;
	h2 {
		max-width: 100%;
		transition: none !important;
	}
}

.swiper-button-prev,
.swiper-button-next {
	height: 115% !important;
	transform: translateY(-50%);
	transition: opacity 0.5s ease-in-out;
	width: 100px !important;
	&:after {
		display: none;
	}
	&.swiper-button-disabled {
		opacity: 0;
	}
}

.swiper-slide {
	min-height: 250px;
	h2 {
		font-size: 18px !important;
	}
	@media (max-width: 1023px) {
		h2 {
			font-size: 18px !important;
		}
	}
}

.swiper-button-prev {
	background: linear-gradient(90deg, white 10%, transparent 100%);
	left: 0 !important;
	&:before {
		background: url('./assets/prev-arrow.svg');
		content: '';
		background-position: left center;
		background-size: 50px 50px;
		background-repeat: no-repeat;
		height: 100%;
		width: 100%;
	}
}
.swiper-button-next {
	background: linear-gradient(90deg, transparent 0%, white 90%);
	right: 0 !important;
	&:before {
		background: url('./assets/next-arrow.svg');
		content: '';
		background-position: right center;
		background-size: 50px 50px;
		background-repeat: no-repeat;
		height: 100%;
		width: 100%;
	}
}

.box-kit-section {
	background: $c-green;
}

.animate_shakeY {
	// animation-name: shakeY;
	// animation-duration: 1s;
	// animation-iteration-count: infinite;
	animation: shakeY 1s infinite, around 5s infinite linear;
	height: 104px;
	width: auto;
	z-index: 100;
}

@keyframes shakeY {
	from,
	to {
		transform: translate3d(0, 5px, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(0, 4px, 0);
	}

	20%,
	40%,
	60%,
	80% {
		transform: translate3d(0, 6px, 0);
	}
}

@keyframes around {
	from {
		left: -15%;
	}
	to {
		left: 115%;
	}
}

.contact-card {
	background: $c-green;
	box-shadow: 0 10px 10px 0 rgba($c-green, 0.25);
	border-radius: 15px;
}

.puesto-vector {
	transform: translateY(42px);
	z-index: 2;
}

.the-main-footer {
	background: $c-red;
}

.rappi-logo,
.uber-logo,
.didi-logo {
	height: 30px;
}

.uber-logo {
	height: 25px;
}

.pizzakit {
	img {
		height: auto;
		width: 80%;
	}
	@media (max-width: 580px) {
		img {
			height: auto;
			width: 100%;
		}
	}
}

.the-background-swiper {
	z-index: 0;
	.swiper-container {
		height: 100%;
		width: 100%;
		.swiper-button-next,
		.swiper-button-prev {
			cursor: pointer;
			background: transparent;
			pointer-events: auto !important;
			z-index: 10;
		}
		.swiper-button-prev {
			height: 70px !important;
			width: 70px !important;
			&:before {
				background: url('./assets/prev-arrow2.svg');
				background-color: $c-red;
				background-position: center;
				background-size: 40px;
				background-repeat: no-repeat;
				border-bottom-right-radius: 10px;
				border-top-right-radius: 10px;
				box-shadow: 5px 5px 5px rgba(black, 0.15);
				height: 100%;
				width: 100%;
			}
		}
		.swiper-button-next {
			height: 70px !important;
			width: 70px !important;
			&:before {
				background: url('./assets/next-arrow2.svg');
				background-color: $c-red;
				background-position: center;
				background-size: 40px;
				background-repeat: no-repeat;
				border-bottom-left-radius: 10px;
				border-top-left-radius: 10px;
				box-shadow: -5px 5px 5px rgba(black, 0.15);
				height: 100%;
				width: 100%;
			}
		}
		.swiper-slide {
			background-size: cover !important;
			background-position: center !important;
			&.swiper-slide-active {
				opacity: 1;
			}
		}
	}
}

.with-z-1 {
	position: relative;
	z-index: 1;
}

.the-fake-hr {
	background-size: cover 100% !important;
	background-position: center !important;
	// background-attachment: fixed !important;
	background-repeat: repeat-x !important;
	height: 45px;
}

.theMapHolder {
	box-shadow: 0 10px 10px 0 rgba($c-green, 0.25);
	border-radius: 20px;
	border: 2px solid #28988a;
	height: 250px;
	width: 100%;
	overflow: hidden;
	z-index: 2;
}

.header-pizza-slide {
	@media (max-width: 767px) {
		.swiper-button-prev,
		.swiper-button-next {
			display: none;
		}
	}
}

.pizza-swiper {
	@media (max-width: 767px) {
		.swiper-button-prev,
		.swiper-button-next {
			background: transparent !important;
		}
	}
}
